import React from 'react';
import { Code } from 'lucide-react';

const Projects = () => {
  const projects = [
    {
      title: "P.A.V.E.L - Professional Automated Virtual Executive Liaison",
      description: "Engineered a sophisticated AI-powered personal brand platform that showcases professional experience through interactive conversations. Built with a modern tech stack featuring a Python backend for AI processing and a responsive React frontend. The system demonstrates both technical expertise in full-stack development and practical AI implementation.",
      impact: "Created a unique, interactive way for employers to explore my professional background while demonstrating practical AI implementation skills. The system handles real-time conversation processing while maintaining consistent, low-latency responses.",
      technologies: [
        // Frontend
        "React.js", 
        "TypeScript",
        "TailwindCSS",
        "CSS3",
        "HTML5",
        // Backend
        "Python",
        "FastAPI",
        "OpenAI API",
        // Infrastructure
        "Node.js", 
        "Express.js",
        "AWS Lambda",
        "DynamoDB",
        "Docker",
        "WebSocket"
      ]
    },
    {
      title: "Orchestra - Enterprise DeFi Protocol Automation Suite",
      description: "Architected and implemented a comprehensive DeFi automation system for the Solana blockchain, featuring advanced smart contract deployment and sophisticated market management capabilities. The system includes real-time market analysis, automated trading strategies, and risk management protocols with deep integration into major Solana DeFi protocols.",
      impact: "Achieved significant improvement in trading efficiency and reduced operational overhead for DeFi operations, while maintaining optimal execution across multiple liquidity pools and protocols.",
      technologies: [
        // Core
        "TypeScript",
        "JavaScript",
        "Solana Web3.js",
        // Frameworks & Protocols
        "Anchor Framework",
        "Raydium SDK",
        "OpenBook DEX",
        "Serum DEX",
        "Project Serum",
        // Development & Infrastructure
        "Rust",
        "Solana CLI",
        "Redis",
        "WebSocket",
        "Python (Data Analysis)"
      ]
    },
    {
      title: "Cross-Chain Crypto Trading Bot",
      description: "Developed an advanced AI-driven trading bot that performs real-time analysis across Ethereum and Solana networks. Implemented sophisticated algorithms for analyzing social sentiment, market indicators, and on-chain metrics to identify trading opportunities. Integrated with major DEXs for comprehensive market analysis.",
      impact: "Platform successfully processes thousands of data points daily, providing actionable trading insights with high accuracy. Enables real-time monitoring and execution across multiple blockchain networks.",
      technologies: [
        // Ethereum Stack
        "Ethereum Web3.js",
        "Remix IDE",
        "MetaMask SDK",
        "Uniswap SDK",
        "Hardhat",
        // Solana Stack
        "Solana Web3.js",
        "Phantom Wallet API",
        // Core Development
        "TypeScript",
        "TensorFlow.js",
        "GraphQL",
        "Node.js",
        "Web3 API",
        // Infrastructure
        "AWS",
        "Docker"
      ]
    },
    {
        title: "Affix - Automotive Sales Pipeline Management System",
        description: "Architected and developed a prototype for an innovative mobile-first sales management platform aimed at modernizing automotive sales processes. A full-featured system incorporating real-time customer tracking, GPS test drive monitoring, and advanced CRM integration. The platform demonstrated the potential to revolutionize traditional sales processes through digital transformation.",
        impact: "Prototype demonstrated significant potential for process optimization, with test scenarios showing estimated 40% reduction in administrative tasks and improved lead tracking capabilities. Development process provided valuable insights into automotive industry software integration requirements and mobile-first architecture design.",
        technologies: [
          // Mobile Development
          "React Native",
          "Swift",
          // Backend
          "Node.js",
          "MongoDB",
          "Express.js",
          // Real-time Features
          "Socket.io",
          "Google Maps API",
          // Infrastructure & State Management
          "Firebase",
          "Redux",
          "JWT Authentication"
        ]
    },
    {
      title: "Computer Vision Intelligence System",
      description: "Implemented state-of-the-art YOLOv8 machine learning models for advanced computer vision applications. Developed a scalable system capable of real-time object detection and image classification with high accuracy and performance.",
      impact: "Achieved 95%+ accuracy in object detection while maintaining real-time processing capabilities.",
      technologies: [
        // Core ML & CV
        "Python",
        "PyTorch",
        "YOLOv8",
        "OpenCV",
        "TensorFlow",
        // Infrastructure & Processing
        "CUDA",
        "Docker",
        "FastAPI",
        // Data Processing
        "NumPy",
        "Pandas"
      ]
    }
  ];

  return (
    <div className="min-h-screen p-4 sm:p-6 md:p-8 pt-24 sm:pt-28 md:pt-32 bg-[#242936]">
      <div className="max-w-5xl mx-auto">
        <div className="mb-8 sm:mb-12 text-center">
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-black tracking-wider text-white mb-2 sm:mb-4">
            Technical Projects
          </h1>
          <p className="text-base sm:text-lg md:text-xl font-light text-gray-300">
            Innovative Solutions Driving Business Value
          </p>
        </div>

        <div className="space-y-8">
          {projects.map((project, index) => (
            <div 
              key={index} 
              className="bg-gray-800/40 backdrop-blur-md rounded-lg p-8 border border-gray-700/50 hover:border-blue-500/50 transition-colors"
            >
              <div className="flex items-start justify-between mb-4">
                <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-white">{project.title}</h2>
                <Code className="w-6 h-6 text-blue-400" />
              </div>
              
              <p className="text-gray-300 mb-4 leading-relaxed">
                {project.description}
              </p>
              
              <div className="mb-4">
                <h3 className="text-lg font-semibold text-blue-400 mb-2">Business Impact</h3>
                <p className="text-gray-300">
                  {project.impact}
                </p>
              </div>

              <div>
                <h3 className="text-lg font-semibold text-blue-400 mb-3">Technologies Used</h3>
                <div className="flex flex-wrap gap-2">
                  {project.technologies.map((tech, techIndex) => (
                    <span
                      key={techIndex}
                      className="px-3 py-1 bg-blue-500/20 text-blue-400 rounded-full text-sm"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
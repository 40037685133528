import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IoChatboxEllipsesSharp } from 'react-icons/io5';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  
  const isActive = (path) => {
    return location.pathname === path ? 'text-blue-400' : 'text-white';
  };

  const isMainPage = location.pathname === '/';

  return (
    <nav className="fixed top-0 left-0 right-0 bg-gray-900/95 backdrop-blur-md border-b border-gray-700 z-[100]">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center space-x-8">
            {!isMainPage && (
              <Link 
                to="/" 
                className="text-blue-400 hover:text-blue-300 transition-all duration-300 transform hover:scale-110"
                aria-label="Chat"
              >
                <div className="relative">
                  <IoChatboxEllipsesSharp className="text-3xl animate-pulse" />
                  <div className="absolute -top-1 -right-1 w-2 h-2 bg-blue-400 rounded-full animate-ping" />
                </div>
              </Link>
            )}
            <div className="hidden md:flex items-center space-x-8">
              <Link 
                to="/resume" 
                className={`${isActive('/resume')} hover:text-blue-400 transition-colors uppercase`}
              >
                RESUME
              </Link>
              <Link 
                to="/about" 
                className={`${isActive('/about')} hover:text-blue-400 transition-colors uppercase`}
              >
                ABOUT
              </Link>
              <Link 
                to="/projects" 
                className={`${isActive('/projects')} hover:text-blue-400 transition-colors uppercase`}
              >
                PROJECTS
              </Link>
              <Link 
                to="/contact" 
                className={`${isActive('/contact')} hover:text-blue-400 transition-colors uppercase`}
              >
                CONTACT
              </Link>
            </div>
          </div>

          <button
            className="md:hidden text-white p-2 hover:text-blue-400 transition-colors"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          >
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>

        {/* Mobile menu dropdown */}
        <div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden absolute left-0 right-0 bg-gray-900 backdrop-blur-sm border-b border-gray-700`}>
          <div className="flex flex-col py-3 px-4 space-y-1">
            <Link 
              to="/resume" 
              className={`
                ${isActive('/resume')} 
                py-3 px-4 rounded-lg
                hover:bg-gray-700/50 hover:text-blue-400 
                transition-all uppercase font-medium
              `}
              onClick={() => setIsMenuOpen(false)}
            >
              RESUME
            </Link>
            <Link 
              to="/about" 
              className={`
                ${isActive('/about')} 
                py-3 px-4 rounded-lg
                hover:bg-gray-700/50 hover:text-blue-400 
                transition-all uppercase font-medium
              `}
              onClick={() => setIsMenuOpen(false)}
            >
              ABOUT
            </Link>
            <Link 
              to="/projects" 
              className={`
                ${isActive('/projects')} 
                py-3 px-4 rounded-lg
                hover:bg-gray-700/50 hover:text-blue-400 
                transition-all uppercase font-medium
              `}
              onClick={() => setIsMenuOpen(false)}
            >
              PROJECTS
            </Link>
            <Link 
              to="/contact" 
              className={`
                ${isActive('/contact')} 
                py-3 px-4 rounded-lg
                hover:bg-gray-700/50 hover:text-blue-400 
                transition-all uppercase font-medium
              `}
              onClick={() => setIsMenuOpen(false)}
            >
              CONTACT
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '../../context/NavigationContext';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';
import NewChatButton from './NewChatButton';
import RobotIcon from '../RobotIcon';
import { Send, RefreshCw } from 'lucide-react';

const MobileChatInput = ({ onSubmit, onNewChat, isLoading, placeholder }) => {
  const [input, setInput] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!input.trim()) return;
    onSubmit(input.trim());
    setInput('');
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-[#242936]/95 backdrop-blur-sm border-t border-gray-700">
      <div className="w-full mx-auto">
        <form onSubmit={handleSubmit} className="flex flex-col">
          <div className="flex items-center gap-2 p-2">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={placeholder}
              className="chat-input flex-1 min-h-[44px]"
              disabled={isLoading}
            />
            <button
              type="submit"
              disabled={isLoading || !input.trim()}
              className="p-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                       transition-colors disabled:opacity-50 disabled:cursor-not-allowed
                       min-w-[44px] min-h-[44px] flex items-center justify-center"
            >
              <Send className="w-5 h-5" />
            </button>
          </div>
          <button
            onClick={onNewChat}
            disabled={isLoading}
            className="flex items-center justify-center gap-2 p-2 mx-2 mb-2
                    bg-gray-700/50 hover:bg-gray-600/50 text-white rounded-lg 
                    transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <RefreshCw className="w-4 h-4 text-blue-400" /> {/* Added text-blue-400 */}
            <span className="text-sm">New Chat</span>
          </button>
        </form>
      </div>
    </div>
  );
};

const TypewriterMessage = () => {
  const [displayText, setDisplayText] = useState('');
  const fullMessage = "Greetings! I'm Pavel Vasiljev's AI Liaison, designed to discuss his professional experience and qualifications. Ask about his skills, projects, or any other topics that interest you.";

  useEffect(() => {
    const hasAnimated = localStorage.getItem('hasAnimated');
    
    if (!hasAnimated) {
      let currentIndex = 0;
      const intervalId = setInterval(() => {
        if (currentIndex < fullMessage.length) {
          setDisplayText(fullMessage.substring(0, currentIndex + 1));
          currentIndex++;
        } else {
          clearInterval(intervalId);
          localStorage.setItem('hasAnimated', 'true');
        }
      }, 30);

      return () => clearInterval(intervalId);
    } else {
      setDisplayText(fullMessage);
    }
  }, []);

  return (
    <p className="text-base md:text-2xl text-white font-light mx-auto max-w-3xl">
      {displayText}
      {!localStorage.getItem('hasAnimated') && <span className="animate-pulse">|</span>}
    </p>
  );
};

const Chat = ({ 
  messages, 
  isLoading, 
  isInitialView, 
  setIsInitialView, 
  handleSubmit,
  clearMessages,
  setIsChatActive // New prop for controlling footer visibility
}) => {
  const navigate = useNavigate();
  const { setPendingRedirect } = useNavigation();
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    // Update chat active state to control footer visibility
    if (messages.length > 0 && !isInitialView) {
      setIsChatActive?.(true);
    } else {
      setIsChatActive?.(false);
    }
  }, [messages.length, isInitialView, setIsChatActive]);

  const examplePrompts = [
    {
      title: "Professional Background",
      prompt: "Can you tell me about Pavel's sales leadership experience and transition into software development?"
    },
    {
      title: "Technical Skills",
      prompt: "What technical projects has Pavel worked on as a Freelance Software Developer since April 2023?"
    },
    {
      title: "Blockchain Exploration",
      prompt: "Could you explain how blockchain technology works and its potential impact on the financial sector?"
    },
    {
      title: "AI and Society",
      prompt: "How is artificial intelligence impacting job markets and what are the ethical considerations involved?"
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToBottom = (smooth = true) => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ 
          behavior: smooth ? "smooth" : "auto",
          block: "end"
        });
      }
    }, 100);
  };

  useEffect(() => {
    if (messages.length > 0) {
      if (messages.length === 1) {
        setIsTransitioning(true);
        setTimeout(() => {
          if (chatContainerRef.current) {
            chatContainerRef.current.scrollIntoView({ behavior: "auto" });
            scrollToBottom(false);
          }
          setIsTransitioning(false);
        }, 100);
      } else {
        scrollToBottom(true);
      }
    }
  }, [messages]);

  const handleNavigation = (response) => {
    if (response.redirect_page) {
      setPendingRedirect(response.redirect_page);
      setTimeout(() => {
        navigate(`/${response.redirect_page}`);
      }, 1500);
    }
  };

  const handleMessageSubmit = async (content) => {
    try {
      const response = await handleSubmit(content);
      handleNavigation(response);
    } catch (error) {
      console.error('Error submitting message:', error);
    }
  };

  const handleNewChat = () => {
    if (clearMessages) {
      clearMessages();
    }
    setIsInitialView(true);
    window.scrollTo(0, 0);
  };

  const renderMessage = (message, index) => (
    <div 
      key={`message-${index}-${message.role}`}
      className="chat-message formatted-message"
    >
      <ChatMessage message={message} />
      {message.redirect_page && (
        <div className="text-blue-400 italic mt-2">
          Redirecting you to the {message.redirect_page.charAt(0).toUpperCase() + message.redirect_page.slice(1)} page...
        </div>
      )}
    </div>
  );

  const handlePromptClick = (prompt) => {
    handleMessageSubmit(prompt);
  };

  return (
    <div className="page-container">
      <div className={`chat-layout ${isInitialView ? 'initial-layout' : ''}`}>
        <div className={`title-section ${!isInitialView ? 'md:block hidden' : ''}`}>
          <RobotIcon />
        </div>

        {isInitialView ? (
          <>
            <div className="welcome-container">
              <div className="welcome-message">
                <TypewriterMessage />
              </div>
            </div>

            <div className="chat-input-section initial">
              <div className="chat-input-container">
                <ChatInput 
                  onSubmit={handleMessageSubmit}
                  isLoading={isLoading}
                  placeholder="Chat with P.A.V.E.L"
                />
              </div>
            </div>

            {/* New section */}
            <div className="flex flex-col items-center justify-center my-6 text-center">
              <p className="text-gray-300 text-sm sm:text-base px-4">
                Select an example prompt or type a custom message
              </p>
            </div>

            <div className="example-prompts-container mt-4">
              <div className="example-prompts-grid">
                {examplePrompts.map((item, index) => (
                  <button
                    key={index}
                    className="prompt-button relative border-2 border-blue-500/30 hover:border-blue-500/50 
                              transition-all duration-300"
                    onClick={() => handlePromptClick(item.prompt)}
                    disabled={isLoading}
                  >
                    <span className="absolute top-2 right-2 text-[10px] sm:text-xs text-blue-400 font-medium">
                      Example prompt
                    </span>
                    <h3 className="text-lg font-medium text-white mb-2">
                      {item.title}
                    </h3>
                    <p className="text-gray-400">
                      {item.prompt}
                    </p>
                  </button>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div 
            ref={chatContainerRef} 
            className={`flex-1 flex flex-col transition-all duration-300 pb-32 md:pb-24 ${
              isTransitioning ? 'opacity-0' : 'opacity-100'
            }`}
          >
            <div className="messages-container">
              <div className="messages-scroll">
                <div className="message-list">
                  {messages.map((message, index) => renderMessage(message, index))}
                  {isLoading && (
                    <div className="typing-indicator">
                      <div className="typing-dot" />
                      <div className="typing-dot" />
                      <div className="typing-dot" />
                    </div>
                  )}
                  <div ref={messagesEndRef} />
                </div>
              </div>
            </div>

            <div className="chat-input-section in-conversation">
              <div className="chat-input-container">
                {/* Desktop version */}
                <div className="hidden md:flex items-center gap-4 p-4">
                  <NewChatButton 
                    onClick={handleNewChat}
                    disabled={isLoading}
                  />
                  <div className="flex-1">
                    <ChatInput 
                      onSubmit={handleMessageSubmit}
                      isLoading={isLoading}
                      placeholder="Reply to P.A.V.E.L"
                    />
                  </div>
                </div>
                
                {/* Mobile version */}
                <div className="md:hidden">
                  <MobileChatInput 
                    onSubmit={handleMessageSubmit}
                    onNewChat={handleNewChat}
                    isLoading={isLoading}
                    placeholder="Reply to P.A.V.E.L"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
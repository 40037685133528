import React, { useState } from 'react';
import { Send } from 'lucide-react';

const ChatInput = ({ onSubmit, isLoading, placeholder }) => {
  const [input, setInput] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!input.trim()) return;
    
    onSubmit(input.trim());
    setInput('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="chat-input-form flex gap-2 w-full relative z-10">
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        className="chat-input flex-1 min-h-[44px] sm:min-h-[50px] text-base"
        disabled={isLoading}
      />
      <button
        type="submit"
        disabled={isLoading || !input.trim()}
        className="px-3 sm:px-4 py-2 sm:py-3 bg-blue-600 text-white rounded-lg 
                 hover:bg-blue-700 transition-colors disabled:opacity-50 
                 disabled:cursor-not-allowed min-w-[44px] sm:min-w-[56px]
                 flex items-center justify-center"
      >
        <Send className="w-4 h-4 sm:w-5 sm:h-5" />
      </button>
    </form>
  );
};

export default ChatInput;
import React from 'react';

export default function RobotIcon() {
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="inline-flex items-center justify-center relative">
        <h1 className="text-5xl sm:text-5xl md:text-7xl lg:text-8xl xl:text-9xl font-black tracking-wider text-white">
          <span className="inline-block">P</span>
          <span className="inline-block animate-period-color-1">.</span>
          <span className="inline-block">A</span>
          <span className="inline-block animate-period-color-2">.</span>
          <span className="inline-block">V</span>
          <span className="inline-block animate-period-color-3">.</span>
          <span className="inline-block">E</span>
          <span className="inline-block animate-period-color-4">.</span>
          <span className="inline-block">L</span>
        </h1>
      </div>
      <p className="text-base sm:text-lg md:text-2xl lg:text-3xl font-light text-gray-300 text-center mt-2 whitespace-nowrap px-4 max-w-full overflow-x-auto">
        Professional Automated Virtual Executive Liaison
      </p>
    </div>
  );
}
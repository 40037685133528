import React, { createContext, useContext, useState } from 'react';

const NavigationContext = createContext({
  pendingRedirect: null,
  setPendingRedirect: () => {},
});

export const NavigationProvider = ({ children }) => {
  const [pendingRedirect, setPendingRedirect] = useState(null);

  return (
    <NavigationContext.Provider value={{ pendingRedirect, setPendingRedirect }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};

export default NavigationContext;
import React from 'react';
import { RefreshCw } from 'lucide-react';

const NewChatButton = ({ onClick, disabled }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className="flex items-center gap-2 px-5 py-4 bg-gray-700/50 hover:bg-gray-600/50 text-white rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
    >
      <RefreshCw className="w-5 h-5 text-blue-400" /> {/* Increased size and changed color */}
      <span className="text-base">New Chat</span> {/* Adjusted text size */}
    </button>
  );
};

export default NewChatButton;
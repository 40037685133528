import React from 'react';
import ReactMarkdown from 'react-markdown';
import { User, Bot } from 'lucide-react';

const ChatMessage = ({ message }) => {
  const isUser = message.role === 'user';

  // Function to process the content before rendering
  const preprocessContent = (content) => {
    if (!content) return '';
    
    // Replace triple backticks with single backticks for inline code
    content = content.replace(/```/g, '`');
    
    // Ensure proper list formatting
    content = content.replace(/^\d+\.\s/gm, '1. '); // Normalize numbered lists
    content = content.replace(/^\*\s/gm, '- '); // Normalize bullet points
    
    // Ensure proper spacing for lists
    content = content.replace(/(\n- .*?)(\n)(?!-|\n|$)/g, '$1\n\n');
    
    return content;
  };

  const customComponents = {
    // Handle paragraphs with proper spacing
    p: ({ children }) => (
      <p className="mb-4 last:mb-0 leading-relaxed">{children}</p>
    ),
    
    // Handle lists with proper nesting and spacing
    ul: ({ children }) => (
      <ul className="mb-4 pl-6 space-y-2">{children}</ul>
    ),
    
    ol: ({ children }) => (
      <ol className="mb-4 pl-6 list-decimal space-y-2">{children}</ol>
    ),
    
    li: ({ children }) => (
      <li className="ml-2">{children}</li>
    ),
    
    // Handle inline code and code blocks
    code: ({ inline, children }) => (
      inline ? 
        <code className="px-1 py-0.5 bg-gray-800 rounded text-sm">{children}</code> :
        <pre className="mb-4 p-4 bg-gray-800 rounded-lg overflow-x-auto">
          <code className="text-sm">{children}</code>
        </pre>
    ),
    
    // Handle emphasis and strong text
    em: ({ children }) => (
      <em className="italic">{children}</em>
    ),
    
    strong: ({ children }) => (
      <strong className="font-bold">{children}</strong>
    ),
    
    // Handle headings
    h1: ({ children }) => (
      <h1 className="text-2xl font-bold mb-4">{children}</h1>
    ),
    h2: ({ children }) => (
      <h2 className="text-xl font-bold mb-3">{children}</h2>
    ),
    h3: ({ children }) => (
      <h3 className="text-lg font-bold mb-2">{children}</h3>
    )
  };

  return (
    <div
      className={`flex items-start gap-2 sm:gap-3 mb-3 sm:mb-4 relative z-0 ${
        isUser ? 'justify-end' : 'justify-start'
      }`}
    >
      {!isUser && (
        <div className="w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-blue-600 flex items-center justify-center shadow-lg">
          <Bot className="w-5 h-5 sm:w-6 sm:h-6 text-white" />
        </div>
      )}
      <div
        className={`rounded-lg p-3 sm:p-4 max-w-[85%] sm:max-w-[80%] shadow-lg ${
          isUser ? 'bg-blue-600 text-white' : 'bg-gray-700 text-white'
        } chat-message formatted-message`}
      >
        <ReactMarkdown
          components={customComponents}
          children={preprocessContent(message.content)}
        />
      </div>
      {isUser && (
        <div className="w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-gray-600 flex items-center justify-center shadow-lg">
          <User className="w-5 h-5 sm:w-6 sm:h-6 text-white" />
        </div>
      )}
    </div>
  );
};

export default ChatMessage;
import React, { useState, useEffect } from 'react';
import { Brain, Code, Database } from 'lucide-react';

const Resume = () => {
  const [cryptoValue, setCryptoValue] = useState(0);
  const [protocolValue, setProtocolValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCryptoValue(prev => (prev + Math.random() * 2 - 1) % 100);
      setProtocolValue(prev => (prev + Math.random() * 1.5 - 0.75) % 100);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen p-4 sm:p-6 md:p-8 pt-24 sm:pt-28 md:pt-32 bg-[#242936]">
      <div className="max-w-5xl mx-auto">
        {/* Header Section */}
        <div className="mb-8 sm:mb-12 text-center">
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-black tracking-wider text-white mb-2 sm:mb-4">
            Pavel Vasiljev
          </h1>
        </div>

        {/* Professional Summary */}
        <section className="bg-gray-800/40 backdrop-blur-md rounded-lg p-6 md:p-8 border border-gray-700/50 mb-6 md:mb-8">
          <h2 className="text-xl md:text-2xl font-bold text-white mb-3 md:mb-4">Professional Summary</h2>
          <p className="text-gray-300 leading-relaxed text-sm md:text-base">
            Dynamic sales leader with 12+ years of experience driving revenue through both traditional and digital channels. Proven track record of building and leading high-performing sales teams, implementing technical solutions, and exceeding revenue targets. Successfully managed multi-million-dollar operations while driving digital transformation initiatives.
          </p>
        </section>

        {/* Core Competencies */}
        <section className="bg-gray-800/40 backdrop-blur-md rounded-lg p-6 md:p-8 border border-gray-700/50 mb-6 md:mb-8">
          <h2 className="text-xl md:text-2xl font-bold text-white mb-4 md:mb-6">Core Competencies</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
            {/* Sales Leadership */}
            <div>
              <h3 className="text-lg md:text-xl font-semibold text-blue-400 mb-2 md:mb-3">Sales Leadership</h3>
              <ul className="text-gray-300 space-y-1.5 md:space-y-2 text-sm md:text-base">
                <li>Team Building</li>
                <li>Performance Management</li>
                <li>Process Innovation</li>
                <li>Strategic Planning</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-blue-400 mb-3">Digital Innovation</h3>
              <ul className="text-gray-300 space-y-2">
                <li>Process Automation</li>
                <li>CRM Implementation</li>
                <li>Sales Analytics</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-blue-400 mb-3">Technical Expertise</h3>
              <ul className="text-gray-300 space-y-2">
                <li>Blockchain Development</li>
                <li>Ai / ML</li>
                <li>Full-Stack Development</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-blue-400 mb-3">Software Engineering</h3>
              <ul className="text-gray-300 space-y-2">
                <li>Python, JavaScript, TypeScript</li>
                <li>React, TensorFlow</li>
                <li>AHK, HTML</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-blue-400 mb-3">Business Solutions</h3>
              <ul className="text-gray-300 space-y-2">
                <li>Process Optimization</li>
                <li>Client Relations</li>
                <li>Systems Architecture</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-blue-400 mb-3">Product Development</h3>
              <ul className="text-gray-300 space-y-2">
                <li>Mobile Applications</li>
                <li>Trading Platforms</li>
                <li>Automation Tools</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Professional Experience */}
        <section className="bg-gray-800/40 backdrop-blur-md rounded-lg p-6 md:p-8 border border-gray-700/50 mb-6 md:mb-8">
          <h2 className="text-xl md:text-2xl font-bold text-white mb-4 md:mb-6">Professional Experience</h2>
          
          {/* Freelance Software Developer */}
          <div className="mb-6 md:mb-8 relative">
            {/* Mobile-optimized glowing dot */}
            <div className="absolute left-0 top-2 md:hidden">
              <div className="w-2.5 h-2.5 rounded-full bg-blue-500/80 shadow-[0_0_12px_rgba(59,130,246,0.5)] animate-pulse" />
            </div>
            {/* Desktop border */}
            <div className="hidden md:block absolute left-0 top-0 bottom-0 w-1 border-l-4 border-blue-500/50" />
            
            <div className="pl-4 md:pl-6">
              <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-1 md:gap-0 mb-2">
                <h3 className="text-lg md:text-xl font-semibold text-white">Freelance Software Developer</h3>
                <span className="text-blue-400 text-sm md:text-base">April 2023 – Present</span>
              </div>
              <p className="text-gray-400 text-sm md:text-base mb-2 md:mb-3">Bradenton, FL</p>
              <ul className="list-disc list-inside text-gray-300 space-y-1.5 md:space-y-2 text-sm md:text-base">
                <li>Engineered DeFi Protocol Automation Suite with smart contract deployment and market management capabilities</li>
                <li>Developed AI-driven crypto trading bot analyzing multiple criteria for Ethereum and Solana networks</li>
                <li>Designed an Automotive Sales Pipeline Management System with real-time tracking and CRM integration</li>
                <li>Implemented YOLOv8 machine learning models for advanced image classification and object detection</li>
              </ul>
            </div>
          </div>

          {/* General Sales Manager */}
          <div className="mb-6 md:mb-8 relative">
            <div className="absolute left-0 top-2 md:hidden">
              <div className="w-2.5 h-2.5 rounded-full bg-blue-500/80 shadow-[0_0_12px_rgba(59,130,246,0.5)] animate-pulse" />
            </div>
            <div className="hidden md:block absolute left-0 top-0 bottom-0 w-1 border-l-4 border-blue-500/50" />
            
            <div className="pl-4 md:pl-6">
              <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-1 md:gap-0 mb-2">
                <h3 className="text-lg md:text-xl font-semibold text-white">General Sales Manager</h3>
                <span className="text-blue-400 text-sm md:text-base">November 2017 - March 2023</span>
              </div>
              <p className="text-gray-400 text-sm md:text-base mb-2 md:mb-3">Gettel Hyundai of Lakewood, Bradenton, FL</p>
              <ul className="list-disc list-inside text-gray-300 space-y-1.5 md:space-y-2 text-sm md:text-base">
                <li>Led 25+ member sales team to achieve $20M+ in annual revenue</li>
                <li>Developed and implemented comprehensive sales strategies across digital and traditional channels</li>
                <li>Consistently broke sales records through optimized sales processes and training programs</li>
                <li>Directed inventory acquisition across wholesale, trade-ins, and retail channels</li>
                <li>Implemented digital tools such as Slack to streamline operations</li>
                <li>Orchestrated inventory pricing optimization and cross-channel marketing initiatives</li>
              </ul>
            </div>
          </div>

          {/* Internet Sales Manager */}
          <div className="mb-6 md:mb-8 relative">
            <div className="absolute left-0 top-2 md:hidden">
              <div className="w-2.5 h-2.5 rounded-full bg-blue-500/80 shadow-[0_0_12px_rgba(59,130,246,0.5)] animate-pulse" />
            </div>
            <div className="hidden md:block absolute left-0 top-0 bottom-0 w-1 border-l-4 border-blue-500/50" />
            
            <div className="pl-4 md:pl-6">
              <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-1 md:gap-0">
                <h3 className="text-lg md:text-xl font-semibold text-white">Internet Sales Manager</h3>
                <div className="text-sm md:text-base">
                  <p className="text-blue-400">May 2013 – October 2013</p>
                  <p className="text-blue-400">January 2015 - May 2016</p>
                </div>
              </div>
              <p className="text-gray-400 text-sm md:text-base mb-2 md:mb-3">Multiple Gettel Dealerships, Central Florida</p>
              <ul className="list-disc list-inside text-gray-300 space-y-1.5 md:space-y-2 text-sm md:text-base">
                <li>Implemented streamlined sales operations integrating digital and traditional channels</li>
                <li>Led sales team to convert 900+ monthly leads with 12-15% close rate</li>
                <li>Optimized CRM strategies achieving sub-5-minute lead response times</li>
                <li>Managed dealership's digital presence including SEO/SEM</li>
              </ul>
            </div>
          </div>

          {/* Corporate Trainer */}
          <div className="mb-6 md:mb-8 relative">
            <div className="absolute left-0 top-2 md:hidden">
              <div className="w-2.5 h-2.5 rounded-full bg-blue-500/80 shadow-[0_0_12px_rgba(59,130,246,0.5)] animate-pulse" />
            </div>
            <div className="hidden md:block absolute left-0 top-0 bottom-0 w-1 border-l-4 border-blue-500/50" />
            
            <div className="pl-4 md:pl-6">
              <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-1 md:gap-0 mb-2">
                <h3 className="text-lg md:text-xl font-semibold text-white">Corporate Trainer</h3>
                <span className="text-blue-400 text-sm md:text-base">November 2013 - April 2014</span>
              </div>
              <p className="text-gray-400 text-sm md:text-base mb-2 md:mb-3">Gettel Automotive Group, Central Florida</p>
              <ul className="list-disc list-inside text-gray-300 space-y-1.5 md:space-y-2 text-sm md:text-base">
                <li>Designed and deployed systematic follow-up schedules and communication templates</li>
                <li>Created comprehensive training curriculum integrating digital and traditional sales methodologies</li>
                <li>Spearheaded group-wide digital transformation across 16 dealerships</li>
              </ul>
            </div>
          </div>

          {/* Internet Sales Consultant */}
          <div className="mb-6 md:mb-8 relative">
            <div className="absolute left-0 top-2 md:hidden">
              <div className="w-2.5 h-2.5 rounded-full bg-blue-500/80 shadow-[0_0_12px_rgba(59,130,246,0.5)] animate-pulse" />
            </div>
            <div className="hidden md:block absolute left-0 top-0 bottom-0 w-1 border-l-4 border-blue-500/50" />
            
            <div className="pl-4 md:pl-6">
              <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-1 md:gap-0 mb-2">
                <h3 className="text-lg md:text-xl font-semibold text-white">Internet Sales Consultant</h3>
                <span className="text-blue-400 text-sm md:text-base">March 2012 - April 2013</span>
              </div>
              <p className="text-gray-400 text-sm md:text-base mb-2 md:mb-3">Gettel Acura - Centralized BDC, Sarasota, Florida</p>
              <ul className="list-disc list-inside text-gray-300 space-y-1.5 md:space-y-2 text-sm md:text-base">
                <li>Managed 150+ monthly digital leads and inbound phone inquiries</li>
                <li>Utilized CRM software to maintain detailed customer interaction logs</li>
                <li>Generated additional leads through outbound calls</li>
                <li>Maintained top Internet Sales Consultant position throughout tenure</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Technical Skills */}
        <section className="bg-gray-800/40 backdrop-blur-md rounded-lg p-6 md:p-8 border border-gray-700/50 mb-6 md:mb-8">
          <h2 className="text-xl md:text-2xl font-bold text-white mb-4 md:mb-6">Technical Skills & Projects</h2>
          
          {/* Blockchain & AI */}
          <div className="mb-6 md:mb-8">
            <h3 className="text-lg md:text-xl font-semibold text-blue-400 mb-3 md:mb-4">Blockchain & AI</h3>
            <div className="space-y-4">
            <div className="bg-gray-700/50 rounded-lg p-4">
              <h4 className="text-lg font-medium text-gray-300 mb-2">Orchestra - DeFi Protocol Automation</h4>
              <p className="text-gray-300 text-sm mb-3">
                Engineered DeFi Protocol Automation Suite with smart contract deployment and market management capabilities. Integrated multiple DEX SDKs for cross-platform liquidity management and automated trading strategies.
              </p>
              <div className="flex flex-wrap gap-2 mb-2">
                {[
                  "TypeScript",
                  "JavaScript",
                  "Solidity",
                  "Web3.js",
                  "Smart Contracts",
                  "Raydium SDK",
                  "Openbook SDK"
                ].map((tech, idx) => (
                  <span key={idx} className="px-2 py-1 bg-blue-500/50 text-blue-400 rounded text-sm hover:bg-blue-500/60 transition-colors">
                    {tech}
                  </span>
                ))}
              </div>
              <div className="mt-2 p-2 bg-gray-800/50 rounded">
                <div className="flex items-center justify-between mb-1">
                  <span className="text-xs text-gray-400">Protocol Activity</span>
                  <span className="text-xs text-blue-400">
                    {Math.max(85, Math.min(98, protocolValue + 88)).toFixed(1)}%
                  </span>
                </div>
                <div className="w-full bg-gray-700/50 rounded-full h-1">
                  <div 
                    className="bg-blue-500/50 h-1 rounded-full transition-all duration-1000 ease-in-out" 
                    style={{
                      width: `${Math.max(85, Math.min(98, protocolValue + 88))}%`
                    }} 
                  />
                </div>
              </div>
            </div>

            <div className="bg-gray-700/50 rounded-lg p-4">
              <h4 className="text-lg font-medium text-gray-300 mb-2">Crypto Trading Bot</h4>
              <p className="text-gray-300 text-sm mb-3">
                Developed a crypto trading bot analyzing multiple criteria for Ethereum and Solana networks. Implemented cross-chain trading strategies utilizing multiple DEX protocols and wallet integrations.
              </p>
              <div className="flex flex-wrap gap-2 mb-2">
                {[
                  "TypeScript",
                  "JavaScript",
                  "Python",
                  "Machine Learning",
                  "Blockchain",
                  "Raydium SDK",
                  "Openbook SDK",
                  "Metamask SDK",
                  "Uniswap SDK"
                ].map((tech, idx) => (
                  <span key={idx} className="px-2 py-1 bg-blue-500/50 text-blue-400 rounded text-sm hover:bg-blue-500/60 transition-colors">
                    {tech}
                  </span>
                ))}
              </div>
              <div className="mt-2 p-2 bg-gray-800/50 rounded">
                <div className="flex items-center justify-between mb-1">
                  <span className="text-xs text-gray-400">Market Analysis</span>
                  <span className="text-xs text-blue-400">
                    {Math.max(65, Math.min(82, cryptoValue + 70)).toFixed(1)}%
                  </span>
                </div>
                <div className="w-full bg-gray-700/50 rounded-full h-1">
                  <div 
                    className="bg-blue-500/50 h-1 rounded-full transition-all duration-1000 ease-in-out" 
                    style={{
                      width: `${Math.max(65, Math.min(82, cryptoValue + 70))}%`
                    }} 
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

            {/* Development */}
            <div>
              <h3 className="text-lg md:text-xl font-semibold text-blue-400 mb-3 md:mb-4">Development</h3>
              <div className="space-y-4">
              <div className="bg-gray-700/50 rounded-lg p-4">
                <h4 className="text-lg font-medium text-gray-300 mb-2">P.A.V.E.L - AI Resume Platform</h4>
                <p className="text-gray-300 text-sm mb-3">
                  Engineered a sophisticated AI-powered personal brand platform that showcases professional experience through interactive conversations
                </p>
                <div className="flex flex-wrap gap-2 mb-2">
                  {["React", "Python", "OpenAI", "Full Stack"].map((tech, idx) => (
                    <span key={idx} className="px-2 py-1 bg-blue-500/50 text-blue-400 rounded text-sm">
                      {tech}
                    </span>
                  ))}
                </div>
                <div className="flex space-x-4 mt-2 text-blue-400">
                  <Brain className="animate-pulse" size={24} />
                  <Code className="animate-bounce" size={24} />
                  <Database className="animate-pulse" size={24} />
                </div>
              </div>

              <div className="bg-gray-700/50 rounded-lg p-4">
                <h4 className="text-lg font-medium text-gray-300 mb-2">Affix - Automotive Sales Pipeline System</h4>
                <p className="text-gray-300 text-sm mb-3">
                  Designed an iOS application and web platform for comprehensive dealership sales management. System features real-time customer tracking, inventory integration, and guided sales process workflows. Management dashboard provides live visibility of customer journey and sales activities.
                </p>
                <div className="flex flex-wrap gap-2 mb-2">
                  {["React Native", "Node.js", "iOS", "Real-time Tracking", "CRM Integration"].map((tech, idx) => (
                    <span key={idx} className="px-2 py-1 bg-blue-500/50 text-blue-400 rounded text-sm">
                      {tech}
                    </span>
                  ))}
                </div>
                <div className="flex flex-wrap gap-2 mt-2">
                  <div className="bg-blue-500/20 px-3.5 py-1.5 rounded animate-[pulse_3.5s_ease-in-out_infinite] hover:bg-blue-500/25">
                    <span className="text-blue-400 whitespace-nowrap text-sm sm:text-base">Mobile App</span>
                  </div>
                  <div className="bg-blue-500/20 px-3.5 py-1.5 rounded animate-[pulse_3.5s_ease-in-out_infinite] hover:bg-blue-500/25">
                    <span className="text-blue-400 whitespace-nowrap text-sm sm:text-base">Web Dashboard</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      {/* Additional Interests */}
      <section className="bg-gray-800/40 backdrop-blur-md rounded-lg p-6 md:p-8 border-2 border-gray-700/50 mb-6 md:mb-8 shadow-lg">
        <h2 className="text-xl md:text-2xl font-bold text-white mb-4 md:mb-6">Additional Interests</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
          <div className="bg-gray-700/30 rounded-lg p-4 md:p-6 border border-gray-600/30 hover:border-gray-600/50 transition-colors">
            <h3 className="text-lg md:text-xl font-semibold text-blue-400 mb-2 md:mb-3">Music Production & Creativity</h3>
            <p className="text-gray-300 text-sm md:text-base">
              Professional audio engineering and multi-instrumental proficiency, demonstrating creative and technical expertise
            </p>
          </div>
          <div className="bg-gray-700/30 rounded-lg p-4 md:p-6 border border-gray-600/30 hover:border-gray-600/50 transition-colors">
            <h3 className="text-lg md:text-xl font-semibold text-blue-400 mb-2 md:mb-3">Physical Fitness</h3>
            <p className="text-gray-300 text-sm md:text-base">
              Fitness enthusiast, highlighting discipline and goal-oriented mindset
            </p>
          </div>
        </div>
      </section>

        {/* References */}
        <section className="bg-gray-800/40 backdrop-blur-md rounded-lg p-6 md:p-8 border border-gray-700/50 mb-24">
          <h2 className="text-xl md:text-2xl font-bold text-white mb-4">References</h2>
          <p className="text-gray-300 text-sm md:text-base">
            Professional references from previous employers are available upon request.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Resume;
import React from 'react';
import { Briefcase, Heart, Music, Dumbbell, Globe } from 'lucide-react';
import profileImage from './assets/profile.jpg'; 

const About = () => {
  return (
    <div className="min-h-screen p-4 sm:p-6 md:p-8 pt-24 sm:pt-28 md:pt-32 bg-[#242936]">
      <div className="max-w-5xl mx-auto">
        <div className="mb-8 sm:mb-12 text-center">
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-black tracking-wider text-white mb-2 sm:mb-4">
            The Human Behind The Code
          </h1>
          <p className="text-base sm:text-lg md:text-xl font-light text-gray-300">
            A Journey of Continuous Growth
          </p>
        </div>

        {/* Personal Journey Section */}
        <section className="bg-gray-800/40 backdrop-blur-md rounded-lg p-8 border border-gray-700/50 mb-12">
          <div className="flex flex-col md:flex-row gap-8">
            {/* Image Container */}
            <div className="flex-shrink-0 w-full md:w-48 lg:w-64">
              <div className="relative group">
                <img
                  src={profileImage}
                  alt="Pavel Vasilev"
                  className="w-full h-auto rounded-lg object-cover filter grayscale hover:grayscale-0 transition-all duration-300
                            shadow-lg border border-gray-700/50"
                />
                <div className="absolute inset-0 bg-blue-600/10 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </div>
            </div>

            {/* Content Container */}
            <div className="flex-grow">
              <div className="flex items-center mb-6">
                <Globe className="w-6 h-6 text-blue-400 mr-3" />
                <h2 className="text-2xl font-bold text-white">My Journey</h2>
              </div>
              <div className="prose prose-invert max-w-none">
                <p className="text-gray-300 text-lg leading-relaxed">
                  Born in Moscow, Russia and transplanted to sunny Florida at age 5, I embraced adaptation early in life. My career path reflects this adaptability - from building a successful 12-year career in automotive sales leadership to following my passion for technology and software development. Today, I create AI and blockchain solutions while maintaining the same drive for excellence that fueled my success in sales. When I'm not working, you'll find me spending time with family, who ensure I maintain a healthy work-life balance.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Professional Philosophy */}
        <section className="bg-gray-800/40 backdrop-blur-md rounded-lg p-8 border border-gray-700/50 mb-12">
          <div className="flex items-center mb-6">
            <Briefcase className="w-6 h-6 text-blue-400 mr-3" />
            <h2 className="text-2xl font-bold text-white">Professional Philosophy</h2>
          </div>
          <p className="text-gray-300 text-lg leading-relaxed">
          My unique journey from managing multimillion-dollar sales operations to developing AI solutions has taught me two crucial things: how to identify what businesses truly need and how to build it. I can step into any role – whether it's selling the right solution or creating it from scratch. This rare combination of sales leadership and technical expertise means I don't just understand business problems, I can actually solve them. Whether it's through code or communication, I deliver solutions that create real value.
          </p>
        </section>

        {/* Life Beyond Code */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <div className="bg-gray-800/40 backdrop-blur-md rounded-lg p-8 border border-gray-700/50">
            <div className="flex items-center mb-4">
              <Music className="w-8 h-8 text-blue-400 mr-4" />
              <h2 className="text-2xl font-bold text-white">Music Production</h2>
            </div>
            <p className="text-gray-300">
              When I am not busy working, you'll find me in my home studio. As a multi-instrumentalist and audio engineer, 
              I apply the same attention to detail and creative problem-solving to music production as I do to sales and software development. 
              The precision required in audio engineering perfectly complements my technical work in development.
            </p>
          </div>

          <div className="bg-gray-800/40 backdrop-blur-md rounded-lg p-8 border border-gray-700/50">
            <div className="flex items-center mb-4">
              <Dumbbell className="w-8 h-8 text-blue-400 mr-4" />
              <h2 className="text-2xl font-bold text-white">Fitness Enthusiast</h2>
            </div>
            <p className="text-gray-300">
              My commitment to personal growth extends to physical fitness. Regular training keeps me disciplined, 
              focused, and energized. The goal-oriented mindset I maintain in the gym translates directly to my 
              professional life, where I consistently push for excellence and measurable results.
            </p>
          </div>
        </div>

        {/* Personal Life */}
        <section className="bg-gray-800/40 backdrop-blur-md rounded-lg p-8 border border-gray-700/50">
          <div className="flex items-center mb-6">
            <Heart className="w-6 h-6 text-blue-400 mr-3" />
            <h2 className="text-2xl font-bold text-white">Life & Balance</h2>
          </div>
          <p className="text-gray-300 text-lg leading-relaxed mb-6">
            Soon to be married, I understand the importance of work-life balance. My fiancée and our Doberman, Arlo, 
            keep me grounded and remind me that the best ideas come from a refreshed mind. Whether we're out 
            with Arlo or I'm experimenting with new music production techniques, these moments of joy fuel my 
            creativity and problem-solving abilities.
          </p>
          <p className="text-gray-300 text-lg leading-relaxed">
            I believe that a well-rounded life makes for a better professional. The discipline from fitness, 
            creativity from music, and joy from family all contribute to my ability to approach technical 
            challenges with fresh perspectives and innovative solutions.
          </p>
        </section>
      </div>
    </div>
  );
};

export default About;
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { NavigationProvider } from './context/NavigationContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Chat from './components/chat';
import Resume from './components/pages/resume';
import About from './components/pages/about';
import Projects from './components/pages/projects';
import Contact from './components/pages/contact';
// import FloatingChat from './components/chat/FloatingChat';  // Commented out floating chat import
import ScrollToTop from './components/ScrollToTop';

const AppContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialView, setIsInitialView] = useState(true);
  const [isChatActive, setIsChatActive] = useState(false);

  const clearMessages = () => {
    setMessages([]);
    setIsLoading(false);
    setIsInitialView(true);
    setIsChatActive(false);
  };

  useEffect(() => {
    if (location.pathname === '/' && messages.length === 0) {
      setIsInitialView(true);
      setIsChatActive(false);
    }
  }, [location.pathname, messages.length]);

  const shouldShowFooter = () => {
    if (location.pathname === '/') {
      return isInitialView || !isChatActive;
    }
    return true;
  };

  const handleChatSubmit = async (message) => {
    if (!message.trim()) return;

    const userMessage = {
      role: 'user',
      content: message.trim()
    };

    setMessages(prevMessages => [...prevMessages, userMessage]);
    setIsInitialView(false);
    setIsLoading(true);

    try {
      const response = await fetch('https://aipavel.com/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: message,
          temperature: 0.7,
          max_tokens: 800
        }),
      });

      if (!response.ok) throw new Error('Network response was not ok');
      
      const data = await response.json();
      
      const formattedResponse = data.response
        .replace(/\n{3,}/g, '\n\n')
        .trim();

      setMessages(prevMessages => [...prevMessages, {
        role: 'assistant',
        content: formattedResponse,
      }]);

      if (data.redirect_page) {
        setTimeout(() => {
          navigate(`/${data.redirect_page}`);
        }, 1500);
      }

      return data;
      
    } catch (error) {
      console.error('Error:', error);
      setMessages(prevMessages => [...prevMessages, {
        role: 'assistant',
        content: "I apologize, but I'm having trouble connecting to the server. Please try again."
      }]);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main 
        className={`flex-1 bg-[#242936] relative flex flex-col ${
          isChatActive ? 'pb-32' : 'pb-16'
        }`}
      >
        <div className={`main-content relative ${isInitialView ? 'flex-1 flex flex-col' : ''}`}>
          <ScrollToTop />
          <Routes>
            <Route 
              path="/" 
              element={
                <Chat 
                  messages={messages}
                  setMessages={setMessages}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isInitialView={isInitialView}
                  setIsInitialView={setIsInitialView}
                  handleSubmit={handleChatSubmit}
                  clearMessages={clearMessages}
                  setIsChatActive={setIsChatActive}
                />
              } 
            />
            <Route path="/resume" element={<Resume />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </main>
      {shouldShowFooter() && <Footer />}
      {/* Floating chat functionality commented out for potential future use
      <FloatingChat 
        messages={messages}
        isLoading={isLoading}
        onSubmit={handleChatSubmit}
      /> */}
    </div>
  );
};

const App = () => {
  return (
    <NavigationProvider>
      <Router>
        <AppContent />
      </Router>
    </NavigationProvider>
  );
};

export default App;
import React from 'react';

const Footer = () => {
  return (
    <footer className="w-full bg-gray-900 py-4 z-10">
      <div className="max-w-7xl mx-auto px-4 flex justify-between items-center">
        <div>
          <p className="text-sm text-gray-400">&copy; {new Date().getFullYear()} Pavel Vasiljev. All rights reserved.</p>
        </div>
        <div className="flex space-x-6">
          <a 
            href="https://www.linkedin.com/in/pavel-vasiljev-0b6804126/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-gray-400 hover:text-white transition-colors"
          >
            LinkedIn
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;